import { hasSession, isLoggedIn } from '../../auth/schibsted-account';
import { sha256 } from '../../utils/sha256';
import { hasAccess } from '../../utils/access';

function getCurrentDate() {
    return new Intl.DateTimeFormat('no-NB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).format(new Date());
}

async function updateUser() {
    if (!(await isLoggedIn())) {
        return;
    }

    const { uuid } = await hasSession();
    const [subscriberid, subscriber] = await Promise.all([
        sha256(uuid + getCurrentDate())
            .then(String)
            .catch(() => undefined),
        hasAccess()
            .then((access) => (access ? '1' : '0'))
            .catch(() => '0'),
    ]);

    window.mblk5a.updateUser({
        login: 1,
        subscriberid,
        subscriber,
    });
}

async function trackPageView() {
    if (!window.mblk5a) {
        window.addEventListener(
            'mbl:loaded',
            () => {
                trackPageView();
            },
            { once: true },
        );
        return;
    }

    return updateUser();
}

export default {
    trackPageView,
};
