import Lazyload from './Lazyload';

export default function importOnElement(
    callback,
    selector,
    options = { rootMargin: '100% 0px' },
) {
    if (!$$(selector).length) {
        return false;
    }

    return new Promise((resolve) => {
        new Lazyload(selector, options).observe((entry, lazy) => {
            lazy.disconnect();
            const { target } = entry;
            target.classList.add('import-finished');

            resolve(callback());
        });
    });
}
