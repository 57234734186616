/**
 * @param {string} message
 */
export async function sha256(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const buffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
}
