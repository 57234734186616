import { addPreviewVideo } from '../preview-video';

export function handleDrEditionVideo(videoConfiguration) {
    importScript();
    createVideoElement(videoConfiguration);
}

function importScript() {
    if (!window.autoplayQueue) {
        import(
            /* webpackIgnore: true */ 'https://www.vg.no/vgc/vgtv-autoplay/autoplay-preload-latest.js'
        );
    }
}

/**
 * @param {Object} videoConfiguration
 */
function createVideoElement(videoConfiguration) {
    const videoElement = document.createElement('div');

    const {
        id,
        autoplay,
        mute,
        videoLoop,
        vendor,
        node: videoId,
        preview: {
            url: previewUrl = '',
            thumbnail: { url: thumbnailUrl = '' } = {},
        } = {},
    } = videoConfiguration;

    // It may happen that the same video with the same id is used in many teasers then div with this id is already created
    const emptyVideoPlacementWithId =
        document.querySelectorAll(`#${videoId}`) || [];
    const videoElementWrapper = [...emptyVideoPlacementWithId].find(
        (node) => node.children.length === 0,
    );

    if (addVideoPreviewListeners(previewUrl, thumbnailUrl)) {
        return;
    }

    videoElement.classList.add('vgtv-video-container');

    Object.assign(videoElement.dataset, {
        vgtvVideoId: id,
        vgtvVendor: vendor,
        vgtvAutoplay: autoplay,
        vgtvMuted: mute,
        vgtvLoop: videoLoop ? true : false,
        vgtvNa: true,
        vgtvRecommended: false,
        vgtvPulseProvider: 'vg',
        vgtvPulseProductTag: 'partnerstudio',
    });

    videoElementWrapper.appendChild(videoElement);

    window.autoplayQueue = window.autoplayQueue || [];
    window.autoplayQueue.push((autoplay) => {
        autoplay.parse();
    });

    videoElementWrapper.parentNode.addEventListener('click', (event) => {
        event.preventDefault();
    });
}

/**
 * @param {string} previewUrl
 * @param {string} thumbnailUrl
 */
function addVideoPreviewListeners(previewUrl, thumbnailUrl) {
    if (previewUrl) {
        const previewVideo = document.querySelector(
            '.dr-edition-preview .preview-video',
        );

        previewVideo.setAttribute('playsinline', '');

        if (!previewVideo || !thumbnailUrl) {
            return false;
        }

        addPreviewVideo(previewVideo);

        return true;
    }

    return false;
}
