import { isSafari, isVGApp } from './utils/device';
import UserSettings from './utils/UserSettings';

const PREVIEW_VIDEOS_INVIEW = [];

/**
 * This is for resetting preview video in apps
 * if frontpage comes back from hidden state
 *
 */
function addResetSrcListener(target) {
    document.addEventListener('visibilitychange', () => {
        if (
            document.visibilityState === 'visible' &&
            target.classList.contains('preview-video-in-screen')
        ) {
            // eslint-disable-next-line no-self-assign
            target.src = target.src;
        }
    });
}

function resolve(target) {
    if (target.dataset.src) {
        target.src = target.dataset.src;

        if (isSafari()) {
            addResetSrcListener(target);
        }

        delete target.dataset.src;
    }

    target.classList.add('preview-video-in-screen');
}

function play(target, delay = 1000) {
    if (delay === 0) {
        resolve(target);
        return;
    }

    if (!target._previewTimeout) {
        target._previewTimeout = setTimeout(() => resolve(target), delay);
    }
}

function pause(target) {
    clearTimeout(target._previewTimeout);
    target._previewTimeout = null;
    target.classList.remove('preview-video-in-screen');
}

function inView(entry) {
    const { target, isIntersecting } = entry;

    if (isIntersecting) {
        PREVIEW_VIDEOS_INVIEW.push(target);
        const [firstTarget] = PREVIEW_VIDEOS_INVIEW;
        const previewDelay =
            (firstTarget.dataset.previewVideoDelay || 1) * 1000;
        play(firstTarget, previewDelay);
    } else {
        pause(target);
        PREVIEW_VIDEOS_INVIEW.splice(
            PREVIEW_VIDEOS_INVIEW.findIndex((t) => t === target),
            1,
        );

        const [firstTarget] = PREVIEW_VIDEOS_INVIEW;

        if (firstTarget) {
            const previewDelay =
                (firstTarget.dataset.previewVideoDelay ?? 1) * 1000;
            play(firstTarget, previewDelay);
        }
    }
}

function getRootMargin() {
    if (isVGApp()) {
        return '0px 0px -20% 0px';
    }
    return '-5% 0px -20% 0px';
}

const PREVIEW_ELEMENT_SELECTORS = {
    safari: '.preview-video.safari-only',
    default: 'video.preview-video',
};
let observer = null;

/**
 * @param {Document|HTMLElement} searchNode
 */
async function init(searchNode = document) {
    const settings = new UserSettings();

    if (navigator.connection?.saveData === true) {
        return;
    }

    if ((await settings.get('save_data')) === true) {
        return;
    }

    observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => inView(entry));
        },
        {
            threshold: [0.9],
            rootMargin: getRootMargin(),
        },
    );

    const SELECTOR = isSafari() ? 'safari' : 'default';
    const NOT_SELECTOR = isSafari() ? 'default' : 'safari';
    const selector = PREVIEW_ELEMENT_SELECTORS[SELECTOR];
    const notSelector = PREVIEW_ELEMENT_SELECTORS[NOT_SELECTOR];

    $$(notSelector, searchNode).forEach((node) => node.remove());
    const previewNodes = $$(selector, searchNode);
    previewNodes.forEach((node) => observer.observe(node));

    //const variant = Math.random() < 0.5;
    //const variant = true;

    /*if (variant) {
        $$('.continue-watching').forEach(
            (node) => (node.style.display = 'flex'),
        );
        previewNodes.forEach((node) => node.classList.add('variant'));
    }

    //For A/B-testing continue watching
    previewNodes
        .map((node) => node.closest('a'))
        .forEach((node) => {
            const params = new URLSearchParams(node.search);
            params.set(
                'utm_term',
                variant ? 'preview-play-variant-1' : 'preview-play-control-1',
            );
            node.search = params.toString();
        });*/
}

export function addPreviewVideo(node) {
    observer?.observe(node);
}

export default init;
