async function trackPageView({ deferOnload }) {
    await deferOnload();

    $$('#gdprAdTransparencyModal a').forEach((link) => (link.rel = 'noopener'));

    return true;
}

export default {
    trackPageView,
};
