import { importOnElement } from '../../utils';

const SCRIPT_URL =
    'https://cdn.brandmetrics.com/survey/script/958329b0909545fe8f0cdc6dc8ba49d0.js';

async function trackPageView({ getScript, deferOnload }) {
    await deferOnload();

    importOnElement(() => {
        window._brandmetrics = window._brandmetrics || [];
        window._brandmetrics.push({ cmd: '_loadsurvey' });

        getScript(SCRIPT_URL);
    }, '#brandmetrics-survey');

    return true;
}

export default {
    trackPageView,
};
