export default class Lazyload {
    constructor(
        selector,
        options = { rootMargin: '0px' },
        searchNode = document,
    ) {
        this.nodes = Array.from(
            typeof selector === 'string' ? $$(selector, searchNode) : selector,
        );
        this.options = options;
        this.observer = null;
    }

    observe(callback) {
        if (this.nodes.length === 0) {
            return this;
        }

        this.observer = new IntersectionObserver(
            (entries) =>
                entries
                    .filter(({ isIntersecting }) => isIntersecting)
                    .forEach((entry) => {
                        callback(entry, this);
                        this.observer.unobserve(entry.target);
                    }),
            this.options,
        );

        this.nodes.forEach((node) => this.observer.observe(node));

        return this;
    }

    disconnect() {
        this.observer?.disconnect();

        return this;
    }

    unobserve(target) {
        this.observer?.unobserve(target);

        return this;
    }
}
