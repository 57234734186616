let promise = null;
function createPromise() {
    const idle = setTimeout;
    promise = new Promise((resolve) => {
        const load = () => idle(resolve);

        if (document.readyState === 'complete') {
            load();
            return;
        }

        window.addEventListener('load', load);
    });
}

export default () => {
    if (promise === null) {
        createPromise();
    }

    return promise;
};
