import { getConsentForPulse } from '@vgno/utils';
import { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';
import { hasSession } from '../auth/schibsted-account';
import { hasAccess } from '../utils/access';
import timeout from '../utils/timeout';

let actorData = null;
const getActor = () => {
    if (actorData) {
        return actorData;
    }

    // get spid user id with 3s timeout
    actorData = timeout(
        hasSession().then(({ userId }) => ({
            id: userId,
            realm: 'spid.no',
            subscriptionName: hasAccess()
                .then((value) => (value ? 'VG+' : 'No'))
                .catch(() => 'No'),
        })),
        3000,
    ).catch(() => ({
        actor: {
            id: undefined,
        },
    }));

    return actorData;
};

export const trackerInstance = new PulseTracker(
    'vg',
    {
        nativeJwe: window.hermesJwe,
        useBeacon: true,
        altEventHandler: window.pulseEventHandler,
        useBeaconWhenAvailable: true,
        consents: getConsentForPulse().catch(() => {
            console.error('Failed to get consent for pulse', window._tcf_);
            return {};
        }),
        requireAdvertisingOptIn: true,
    },
    {
        actor: getActor(),
    },
);

const routeToPulseObjectMapping = {
    front: {
        id: 'front',
        contentId: 'front',
        type: 'Frontpage',
        category: 'vgfront',
        custom: {
            frontVersion: 'vgbeta',
            supportsImportmap: !!(
                HTMLScriptElement.supports &&
                HTMLScriptElement.supports('importmap')
            ),
            hasDarkmode: window.matchMedia('(prefers-color-scheme: dark)')
                .matches,
        },
    },
    'front-beta': {
        id: 'front-beta',
        contentId: 'front-beta',
        type: 'Frontpage',
    },
    search: {
        id: 'search',
        type: 'Listing',
    },
};

const getOptions = () => {
    const actor = getActor();
    const options = {
        object: routeToPulseObjectMapping[
            document.body.getAttribute('data-route')
        ],
        provider: {
            id: 'vg',
            productType: window.hermesJwe ? 'iOSApp' : 'Web',
            product: 'vg',
        },
        actor,
    };

    return options;
};

let trackingInitialized = null;
const initTracking = () => {
    if (trackingInitialized) {
        return trackingInitialized;
    }

    const options = getOptions();
    trackerInstance.update(options);
    trackingInitialized = options;

    return trackingInitialized;
};

if (window.varnishVaryConfig) {
    trackerInstance.update({
        experiments: window.varnishVaryConfig.map((type) => ({
            id: type,
            platform: 'vg',
        })),
    });
}

const externalInstances = {};
const exposePulseAsGlobal = () => {
    // Run window.pulse.q
    const pulseQueue = window.pulse?.q || [];
    window.pulse = (...args) => {
        if (args.length === 0) {
            return;
        }

        if (typeof args[0] === 'function') {
            args[0](trackerInstance);
        }

        // backwards compatibility for the pulse autotracker
        if (typeof args[0] === 'string') {
            if (args[0] === 'init') {
                const [, pulseClientId, sdkConfig, , trackerId] = args;

                externalInstances[trackerId] = new PulseTracker(pulseClientId, {
                    useBeacon: true,
                    useBeaconWhenAvailable: true,
                    ...sdkConfig,
                });
            } else {
                const [trackerId, cb] = args;

                if (externalInstances[trackerId]) {
                    cb(externalInstances[trackerId]);
                }
            }
        }
    };
    pulseQueue.forEach((fn) => {
        window.pulse.apply(null, fn);
        pulseQueue.shift();
    });
    window.pulse.q = pulseQueue;
};

exposePulseAsGlobal();

export async function trackPageView() {
    initTracking();

    await trackerInstance.trackPageView();

    try {
        const { object } = trackerInstance.builders;
        localStorage.setItem('previousArticle', null);
        localStorage.setItem(
            'previousPage',
            JSON.stringify({
                id: object ? object.id : null,
                url: window.location.href,
                referrer: window.document.referrer,
            }),
        );
    } catch (err) {
        console.error(err);
    }
}

/**
 * @callback readyCallback
 * @param {typeof trackerInstance} sdk
 * @returns void
 */

/**
 * @returns {Promise<typeof trackerInstance>}
 * @param {readyCallback} [callback]
 */
export function ready(callback = null) {
    initTracking();

    if (callback) {
        callback(trackerInstance);
    } else {
        return Promise.resolve(trackerInstance);
    }
}

/**
 * @returns {Promise<string>}
 */
export async function getEnvironmentId() {
    initTracking();

    return trackerInstance.getEnvironmentId();
}

export const getXandrPPIDs = async () => {
    initTracking();
    const actor = await getActor();
    trackerInstance.update({ actor });
    const { ppId1: ppid1, ppId2: ppid2 } =
        (await trackerInstance.getAdvertisingIdentifiers()).xandr || {};

    return { ppid1, ppid2 };
};

/**
 * @typedef {{ '@id': string; '@type': string; name?: string; url: string } & Record<string, any>} Target
 * @typedef {{ '@id': string; target?: Target } & Record<string, any>} Element
 */

/**
 * trackEngagement
 *
 * @param {Object} params
 * @param {string} [params.type]
 * @param {string} [params.action]
 * @param {string} [params.elementId]
 * @param {string} [params.elementType]
 * @param {string} [params.elementName]
 * @param {string} [params.elementAction]
 * @param {string} [params.overrideId]
 * @param {Element[]} [params.elements]
 * @param {Target} [params.target]
 * @param {string} [params.intent]
 * @param {number} [params.scrollPosition]
 * @param {number} [params.duration]
 * @param {any} [params.custom]
 * @param {any} [params.objectExtra]
 * @param {MouseEvent} [params.event]
 * @param {string[]} [params.tags]
 * @param {any} [params.partnerstudio]
 * @param {string} [params.productTag]
 * @returns void
 */
export function trackEngagement({
    type = 'Engagement',
    action,
    elementId,
    elementType,
    elementName,
    elementAction,
    elements,
    target,
    intent,
    scrollPosition,
    duration,
    custom = {},
    objectExtra,
    overrideId = null,
    tags,
    partnerstudio,
    productTag,
}) {
    initTracking();

    const parentObject = trackerInstance.builders.object || {};
    const parentObjectId = parentObject.id || '';
    const parentObjectType = (parentObject.type || '').toLowerCase();

    // TODO: Remove this when out of beta
    custom.frontTrackingVersion = 2.1;
    custom.frontVersion = 'vgbeta';
    custom.isLegacyTest = !process.isModern;

    const parentId = `sdrn:vg:${parentObjectType}:${parentObjectId}`;
    const id = overrideId ?? `${parentId}:element:${elementId}`;

    const object = {
        '@id': id,
        '@type': 'UIElement',
        contentId: elementId,
        elementType,
        name: elementName,
        action: elementAction,
        page: {
            '@id': parentId,
            '@type': 'Frontpage',
            url: location.origin,
        },
        custom,
        tags,
    };

    if (objectExtra && Object.assign) {
        Object.assign(object, objectExtra);
    }

    const event = {
        type,
        action,
        object,
        origin: {
            '@id': parentId,
            '@type': parentObjectType,
            url: parentObject.url,
        },
        elements,
        target,
        intent,
        scrollPosition,
        duration,
        partnerstudio,
        provider: {
            productTag: productTag,
        },
    };

    trackerInstance.track('trackerEvent', event);
}

export default {
    ready,
    getEnvironmentId,
    trackPageView,
};
