import Lazyload from './utils/Lazyload';
import {
    isMobile,
    isSafari,
    supportHover,
    supportNativeImageLazyload,
    supportNativeIFrameLazyload,
} from './utils/device';

const SELECTOR_IMAGE = 'img[data-src][loading="lazy"]:not(.preview-video)';
//const SELECTOR_IFRAME = 'iframe[data-src][loading="lazy"]';
const SELECTOR_IFRAME = 'iframe[data-src]';
const SELECTOR_PREVIEW_VIDEO =
    'video[data-src][loading="lazy"]:not(.preview-video)';

/**
 * @param {HTMLImageElement|HTMLIFrameElement} node
 */
const resolveSrc = (node) => {
    const { dataset } = node;
    const { src, srcset } = dataset;

    if (srcset && node instanceof HTMLImageElement) {
        node.srcset = srcset;
        node.src = src;

        node.removeAttribute('data-srcset');
        node.removeAttribute('data-src');

        return;
    }

    if (src) {
        node.src = src;
        node.removeAttribute('data-src');
    }
};

const getPreviewVideoSelector = () =>
    isSafari() ? '.preview-video.safari-only' : 'video.preview-video';

const initLazyload = () => {
    const assetsWithNativeLazyload = [
        supportNativeIFrameLazyload() && SELECTOR_IFRAME,
        supportNativeImageLazyload() && SELECTOR_IMAGE,
    ].filter(Boolean);
    assetsWithNativeLazyload
        .flatMap((selector) => $$(selector))
        .forEach(
            (node) =>
                (node instanceof HTMLIFrameElement ||
                    node instanceof HTMLImageElement) &&
                resolveSrc(node),
        );

    [
        //supportHover() && {
        {
            selector: SELECTOR_PREVIEW_VIDEO,
            options: {
                threshold: isMobile() ? 0.8 : 0,
            },
        },
        supportHover() && {
            selector: getPreviewVideoSelector(),
            options: {
                threshold: isMobile() ? 0.8 : 0,
            },
        },
        !supportNativeImageLazyload() && {
            selector: SELECTOR_IMAGE,
            options: {
                rootMargin: '50% 0px',
            },
        },
        !supportNativeIFrameLazyload() && {
            selector: SELECTOR_IFRAME,
            options: {
                rootMargin: '100% 0px',
            },
        },
    ]
        .filter(Boolean)
        .forEach(({ selector, options }) =>
            new Lazyload(selector, options).observe(({ target }, lazy) => {
                resolveSrc(target);
                lazy.unobserve(target);
            }),
        );
};

export default initLazyload;
